import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
export const meta = {
  published: false,
  publishedAt: "2019-01-30",
  title: "How does CSS-in-JS work?",
  summary: ""
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Conceptually, CSS-in-JS libraries take your styles, hash them to create a unique class name and insert that whole block into the DOM.`}</p>
    <p>{`For example:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` styled `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"styled-components"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Title`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styled`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`h1`}</span><span parentName="code" {...{
          "className": "token styled-template-string"
        }}><span parentName="span" {...{
            "className": "token string language-css"
          }}>{`\`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`color`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` palevioletred`}</span>{`;`}<span parentName="span" {...{
            "className": "token string language-css"
          }}>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`font-size`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`1.5`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`em`}</span></span>{`;`}<span parentName="span" {...{
            "className": "token string language-css"
          }}>{`
\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`App`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Title`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Hello`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`World`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Title`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`would render the following to the DOM:`}</p>
    <pre {...{
      "className": "language-html"
    }}><code parentName="pre" {...{
        "className": "language-html"
      }}><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h1`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`cMWNzn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`Hello World!`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
`}</code></pre>
    <pre {...{
      "className": "language-css"
    }}><code parentName="pre" {...{
        "className": "language-css"
      }}><span parentName="code" {...{
          "className": "token selector"
        }}><span parentName="span" {...{
            "className": "token class"
          }}>{`.cMWNzn`}</span></span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`color`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` palevioletred`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`font-size`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1.5`}</span><span parentName="code" {...{
          "className": "token unit"
        }}>{`em`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <h4>{`Is CSS-in-JS slow?`}</h4>
    <p>{`No. The CSSOM can insert 10,000 unique CSS rules (i.e. classes) in ~60ms (`}<a parentName="p" {...{
        "href": "https://twitter.com/threepointone/status/758095801558011904"
      }}>{`source`}</a>{`). Since only the CSS of components that are used on the current page is injected, you will never even come close to 10,000 unique CSS rules.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      